import React, { useEffect, useMemo, useState } from 'react';
import { Card, Container, Row } from 'reactstrap';
import format from 'date-fns/format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
    faArrowLeft,
    faFile,
    faArrowRight,
    faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { recordLinkingService } from 'services';
import { UrlEnum } from 'shared';
import {
    Records,
    ReviewHeader,
    ReviewRow,
    EditRow,
    NoMatchRow,
    DuplicateRow,
} from './components';
import { Button } from 'components/blocks';

const paginationOptions = {
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    sizePerPage: 10,
    hideSizePerPage: true,
};

export const LinkReview = props => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(null);
    const [record, setRecord] = useState([]);
    const [active, setActive] = useState('-1');
    const { duplicate, row, races, genders } = props?.location?.state || {};
    useEffect(() => {
        const fetch = async () => {
            try {
                let res;
                if (duplicate) {
                    res = await recordLinkingService.getRecordDuplicate(
                        row.linkRequestID
                    );
                } else {
                    res = await recordLinkingService.getRecordManual(row.linkRequestID);
                }
                const dataWIndex = {
                    ...res,
                    records: res.records.map((r, i) => ({ ...r, index: i })),
                };
                const firstNeedsReviewRecord = dataWIndex.records.find(
                    record => record.reviewed === 'Needs Review'
                );

                if (firstNeedsReviewRecord) {
                    setCurrent(firstNeedsReviewRecord);
                } else {
                    setCurrent(duplicate ? dataWIndex.records : dataWIndex.records[0]);
                }
                setData(dataWIndex);
            } catch (error) {}
        };
        fetch();
    }, []);
    useEffect(() => {
        const fetchRecord = async user => {
            try {
                const val = await recordLinkingService.getReviewRecord(
                    row.linkRequestID,
                    user.id
                );
                const found = val.find(v => v.selected);
                setActive(found?.id || '-1');
                setRecord(val);
            } catch (error) {}
        };
        if (current && !duplicate) {
            fetchRecord(current);
        }
    }, [row, current, duplicate]);
    const submitLink = async () => {
        try {
            await recordLinkingService.submitRecordLink(
                row?.linkRequestID,
                current?.id,
                active
            );
            getNextReviewRecord();
        } catch (error) {}
    };
    const skipRecord = async () => {
        try {
            getNextReviewRecord();
        } catch (error) {}
    };

    const getNextReviewRecord = () => {
        const nextIndex = data.records.findIndex(
            (record, index) => record.reviewed === 'Needs Review' && index > current.index
        );

        if (nextIndex !== -1) {
            const nextRecord = data.records[nextIndex];
            setCurrent(nextRecord);
        } else {
            console.log('No more records needing review.');
        }
    };
    const submitDuplicates = async () => {
        setLoading(true);
        try {
            const promises = current.map(record => {
                return recordLinkingService.submitDuplicate(
                    row.linkRequestID,
                    record.id,
                    record.dupGroupNum
                );
            });
            await Promise.all(promises);
        } catch (error) {}
        setLoading(false);
    };
    const setDuplicate = (val, d) => {
        setCurrent(prev =>
            prev.map(p => {
                if (p.id === d.id) {
                    return { ...p, dupGroupNum: val };
                }
                return p;
            })
        );
    };
    const rowEvents = {
        onClick: (e, row, rowIndex) => {
            e.preventDefault();
            setCurrent(row);
        },
    };
    const {
        linkRequestID,
        dataSetSource,
        dateRequestRan,
        totalRecordN,
        plNeedReviewN,
        autoLinkN,
        autoNonLinkN,
    } = row || {};
    const { autoLinkCutoffScore, possibleLinkCutoffScore, records = [] } = data;
    return (
        <Container fluid>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <a href={UrlEnum.RECORD_LINKING}>
                    <FontAwesomeIcon icon={faArrowLeft} /> back to all records
                </a>
            </div>
            <Row>
                <div className="col-12">
                    <Card className="p-3 mb-3">
                        <div className="row mb-2 border-bottom">
                            <div className="col-md-auto mb-3 md-md-0">
                                <h2 className="text-strong text-primary m-0">
                                    <FontAwesomeIcon icon={faFile} /> {totalRecordN} Total
                                    Records
                                </h2>
                            </div>
                            <div className="col-md-auto mb-3 md-md-0">
                                <h2 className="text-strong text-primary m-0">
                                    <FontAwesomeIcon icon={faSearch} /> {plNeedReviewN}{' '}
                                    Need Review
                                </h2>
                            </div>
                            <div className="col-md-auto mb-3 md-md-0">
                                <h2 className="text-strong text-primary m-0">
                                    <i className="fas fa-solid fa-link" /> {autoLinkN}{' '}
                                    Links
                                </h2>
                            </div>
                            <div className="col-md-auto mb-3 md-md-0">
                                <h2 className="text-strong text-primary m-0">
                                    <i className="fas fa-solid fa-link-slash" />{' '}
                                    {autoNonLinkN} Non-Links
                                </h2>
                            </div>
                        </div>
                        <ul className="d-md-flex align-items-center mr-3 p-0 mb-0 no-bullets med-type">
                            <li className="mr-3">
                                <label className="m-0">ID:</label> {linkRequestID}
                            </li>
                            <li className="mr-3">
                                <label className="m-0">Date Run:</label>{' '}
                                {dateRequestRan
                                    ? format(new Date(dateRequestRan), 'MM/dd/yyyy')
                                    : '---'}
                            </li>
                            <li className="mr-3">
                                <label className="m-0">Data Set Source:</label>{' '}
                                {dataSetSource}
                            </li>
                            <li className="mr-3">
                                <label className="m-0">Auto Link Cut-Off Score:</label>{' '}
                                {autoLinkCutoffScore || '---'}
                            </li>
                            <li className="mr-3">
                                <label className="m-0">
                                    Possible Link Cut-Off Score:
                                </label>{' '}
                                {possibleLinkCutoffScore || '---'}
                            </li>
                        </ul>
                    </Card>
                    <Row>
                        {!duplicate && (
                            <div className="col-xl-12 mb-xl-0 mb-3 order-2">
                                <Card className="record-linking-tabs no-shadow">
                                    <BootstrapTable
                                        classes="records-table data-table table dataTable no-footer dtr-inline"
                                        keyField="id"
                                        data={records}
                                        columns={Records({ active: current })}
                                        pagination={paginationFactory(paginationOptions)}
                                        rowEvents={rowEvents}
                                    />
                                    <div className="p-3"></div>
                                </Card>
                            </div>
                        )}
                        <div className="col-xl-12 order-1 mb-4">
                            <Card className="mb-3">
                                <div className="table-responsive">
                                    <table className="table table-bordered align-middle record-compare-table">
                                        <ReviewHeader duplicate={duplicate} />
                                        {duplicate ? (
                                            <tbody>
                                                {current?.map(row => {
                                                    return (
                                                        <DuplicateRow
                                                            key={row.id}
                                                            data={row}
                                                            races={races}
                                                            genders={genders}
                                                            setDuplicate={setDuplicate}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                        ) : (
                                            <tbody>
                                                {current && (
                                                    <ReviewRow
                                                        data={current}
                                                        races={races}
                                                        genders={genders}
                                                    />
                                                )}
                                                {record?.map(rec => (
                                                    <EditRow
                                                        key={rec.id}
                                                        data={rec}
                                                        races={races}
                                                        genders={genders}
                                                        match={current}
                                                        setActive={setActive}
                                                        active={active === rec.id}
                                                    />
                                                ))}
                                                <NoMatchRow
                                                    setActive={setActive}
                                                    active={active === '-1'}
                                                />
                                            </tbody>
                                        )}
                                    </table>
                                </div>
                            </Card>
                            {duplicate ? (
                                <div className="text-right">
                                    <Button
                                        className="btn-lg btn-success"
                                        loading={loading}
                                        disabled={!current}
                                        onClick={submitDuplicates}
                                    >
                                        Save Duplicate Group Numbers
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <div className="text-right">
                                        <Button
                                            className="btn-lg btn-success"
                                            onClick={submitLink}
                                        >
                                            Save &amp; Go to Next Record{' '}
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </Button>
                                    </div>
                                    <div className="text-right">
                                        <Button className="btn-lg" onClick={skipRecord}>
                                            Skip Record{' '}
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </Button>
                                    </div>
                                </>
                            )}
                        </div>
                    </Row>
                </div>
            </Row>
        </Container>
    );
};
